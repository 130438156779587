





import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import { analysisStores } from '@/store/poultry/analysis/index';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { AnalysisData, ScoreAnalysis, BarChartProperties } from '@/models/poultry/analysis.model';
import { phase2_scheme } from '@/utils/chart.color';
import { phase2_bar_chart, phase3_chart } from '@/utils/chart.config';

@Component({
  components: {},
})
export default class PreparationAnalysisChartBody extends Vue {
  store = analysisStores.preparation.list;
  analysisChart: Chart = new Chart('', {})
  chartKey = Math.random();
  chartColor = phase2_scheme;

  get preparations(): AnalysisData[] {
    return this.store.preparation;
  }

  get monthPreparations() {
    const startDate = this.store.startDate;
    const endDate = this.store.endDate;

    let dates: Dayjs[] = [];
    let dateObj = dayjs(startDate!);
    const startDateObj = dayjs(startDate!).startOf('month').endOf('day');
    const endDateObj = dayjs(endDate!).startOf('month').endOf('day');

    while (dateObj.isBefore(endDateObj)) {
      dates.push(dateObj);
      dateObj = dateObj.add(1, 'month');
    }
    return dates;
  }

  get months(): string[] {
    return this.monthPreparations.map((dateObj) => dateObj.format('MMM YY'));
  }

	get crudeScale() {
    const months = this.months ? this.months.length : 1;
    const datasets = this.dataSets ? this.dataSets.length : 1;
    const paddings = 28 * months;
    const bars = 20 * datasets * months;
    const base = 240;
    return paddings + bars + base;
  }

  scores(preparation: AnalysisData) {
    let scoresArr: number[] = [];
    let data = preparation.data;

    data.forEach(data => {
      scoresArr.push(data.score);
    });

    return scoresArr;
  }

  get dataSets() {
    const resultArr: BarChartProperties[] = [];
    this.preparations.forEach((preparation, i) => {
      resultArr.push({
        label: preparation.name,
        data: this.scores(preparation),
        backgroundColor: this.chartColor[i % this.chartColor.length],
      });
    })
    return resultArr;
  }

  get chartData() {
    return {
      ...phase2_bar_chart,
      data: {
        labels: this.months,
        datasets: this.dataSets,
      },
    };
  }

  createChart(chartId: any, chartData: any) {
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.analysisChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  mounted() {
    this.createChart('analysisChart', this.chartData);
    if (window.matchMedia) {
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener((mql) => {
        if (mql.matches) {
          this.analysisChart.resize();
        }
      });
    }

    window.onbeforeprint = this.analysisChart.resize
  }
}
