








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import PreparationAnalysisTableHeader from './PreparationAnalysisTableHeader.vue';
import PreparationAnalysisTableBody from './PreparationAnalysisTableBody.vue';

@Component({
  components: {
    PreparationAnalysisTableHeader,
    PreparationAnalysisTableBody,
  },
})
export default class PreparationAnalysisTable extends Vue {}
