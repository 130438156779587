










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import PreparationAnalysisPageHeader from './PreparationAnalysisPageHeader.vue';
import { analysisStores } from '@/store/poultry/analysis';
import PreparationAnalysisChart from './PreparationAnalysisChart/PreparationAnalysisChart.vue';
import PreparationAnalysisTable from './PreparationAnalysisTable/PreparationAnalysisTable.vue';

@Component({
  components: {
    PreparationAnalysisPageHeader,
    PreparationAnalysisChart,
    PreparationAnalysisTable,
    EmptyAnalysisPlaceholder 
  }
})
export default class PreparationAnalysis extends Vue {
  store = analysisStores.preparation.list;

  created() {
    this.store.setPreparationCreated(false);
  }

  get isPreparationCreated() {
    return this.store.isCreated;
  }
}
