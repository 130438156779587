






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import PreparationAnalysisChartHeader from './PreparationAnalysisChartHeader.vue';
import PreparationAnalysisChartBody from './PreparationAnalysisChartBody.vue';

@Component({
  components: {
    PreparationAnalysisChartHeader,
    PreparationAnalysisChartBody
  }
})
export default class PreparationAnalysisChart extends Vue {
}
